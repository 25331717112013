import Immutable from "immutable";
import { T } from "../../../../util/t";
import { Button, CircularProgress, Dialog, Divider, Typography } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ORGANISATION_PROPERTY, GET_ORGANISATION_PURCHASES } from "graphql/organisation";
import { UpsellDocumentSigning } from "./UpsellDocumentSigning";
import { ConfigureDocuseal } from "./ConfigureDocuseal";
import { EditDocusealTemplate } from "./EditDocusealTemplate";
import { DocsToSignHelpTooltip } from "./DocsToSignHelpTooltip";
import { useDialogState } from "../../../../../hooks/useDialogState";
import { Add, Settings, WorkspacePremium } from "@mui/icons-material";
import { HelpTooltip } from "../../../../elements/helpTooltip";
import { useRef, useState } from "react";
import { EditDocusealTemplateDialog } from "./EditDocusealTemplateDialog";
import { useError } from "hooks/useNotifications";

const removeDocument = (array, field, document, index) => {
    if (document.get("id")) array.splice(field, index, 1, document.merge({ _destroy: true }));
    else array.remove(field, index);
};

export const DocsToSign = ({ formState, change, array, organisationId }) => {
    const field = "custom_fields_registrations_attributes";
    const value = formState.get(field) || Immutable.List();
    const signatureValue = value?.filter(property => property.get("type") === "signature");
    const { notifyResponseError } = useError();

    const indexRef = useRef(value.size);
    const [error, setError] = useState(false);
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    const { data } = useQuery(GET_ORGANISATION_PURCHASES, { variables: { id: organisationId }, fetchPolicy: "cache-and-network" }),
        organisation = data?.organisation,
        documentsEnabled = !!organisation?.activePurchases.accountAddOns?.documentSigning,
        docusealEnabled = !!organisation?.docusealEnabled;

    const [createProperty] = useMutation(CREATE_ORGANISATION_PROPERTY);

    if (!organisation) return (
        <Button variant="outlined" startIcon={<CircularProgress/>} disabled>
            <T>Documents to sign</T>
        </Button>
    );

    const onTemplateCreated = data => {
        if (!data) return setError(true);
        if (!value.getIn([indexRef.current, "docuseal_template_id"])) {
            createProperty({
                variables: {
                    id: organisationId,
                    property: {
                        type: "signature",
                        level: "registration",
                        label: data.name,
                        required: true,
                        deleted: true,
                        config: {
                            docusealTemplateId: data.id,
                            docusealTemplateSlug: data.slug,
                        }
                    }
                },
                onError: notifyResponseError,
                onCompleted: ({ createOrganisationProperty: { property } }) => array.push(field, Immutable.fromJS({
                    uuid: property.uuid,
                    docuseal_template_id: property.config.docusealTemplateId,
                    custom_field_attributes: { label: property.label }
                }))
            });
        }
    };
    const onTemplateChanged = data => change(`${field}.${indexRef.current}.custom_field_attributes`, Immutable.fromJS({ label: data.name }));

    const onCreate = () => {
        indexRef.current = value.size;
        openDialog();
    };
    const onEdit = index => {
        indexRef.current = index;
        openDialog();
    };

    return (
        <>
            {signatureValue.size > 0 &&
            <Typography variant="label1">
                <T>Documents to sign</T>
                <DocsToSignHelpTooltip/>
            </Typography>}

            {value.map((document, index) =>
                document.get("docuseal_template_id") && !document.get("_destroy") &&
                <EditDocusealTemplate key={index} label={document.getIn(["custom_field_attributes", "label"]) || document.get("label")} onEdit={() => onEdit(index)} onDelete={() => removeDocument(array, field, document, index)}/>
            )}
            <Button variant="outlined" startIcon={!documentsEnabled ? <WorkspacePremium/> : !docusealEnabled ? <Settings/> : <Add/>} onClick={onCreate}>
                {signatureValue.size > 0 ?
                    <T>More documents to sign</T> :
                    <T>Documents to sign</T>
                }
            </Button>
            {signatureValue.size > 0 ?
                <Divider sx={{ marginTop: 2 }}/> :
                <Typography variant="label1">
                    {!documentsEnabled ?
                        <HelpTooltip>
                            <T>Automatically collect signed documents with e-Signatures!<br/><br/>✅ Fast & convenient for athletes<br/>✅ No more chasing paperwork<br/>✅ Documents organised & secure<br/><br/>Contact Liveheats to activate the e-Signatures add-on.</T>
                        </HelpTooltip> :
                        <DocsToSignHelpTooltip/>
                    }
                </Typography>
            }

            <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" className="lh-dialog" aria-labelledby="documents-dialog-title" fullScreen={documentsEnabled && docusealEnabled && !error}>
                {!documentsEnabled
                    ? <UpsellDocumentSigning closeDialog={closeDialog}/>
                    : (!docusealEnabled || error)
                        ? <ConfigureDocuseal organisationId={organisationId} error={error} clearError={() => setError(false)} closeDialog={closeDialog}/>
                        : <EditDocusealTemplateDialog onLoad={onTemplateCreated} onChange={onTemplateChanged} organisationId={organisationId} templateId={value.getIn([indexRef.current, "docuseal_template_id"])} closeDialog={closeDialog}/>
                }
            </Dialog>
        </>
    );
};
